import React, { useState } from "react"
import styled from "styled-components"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Device1 from "images/device-1.png"
import Device2 from "images/device-2.png"
import Device3 from "images/device-3.png"
import Device4 from "images/device-4.png"
import Device5 from "images/device-5.png"
import Device6 from "images/device-6.png"
import Device7 from "images/device-7.png"

const StyledPicture = styled.img`
  width: 610px;
  height: 400px;

  @media (max-width: 1200px) {
    width: 250px;
    height: 250px;
  }

  @media (max-width: 600px) {
    margin: 8px 0;
  }

  @media (min-width: 1750px) {
    width: calc(300px + 0.5vmax);
    height: calc(300px + 0.5vmax);
  }
`

const StyledWrapper = styled.div`
  width: 630px;
  height: 460px;

  @media (max-width: 1200px) {
    width: 250px;
    height: 300px;
  }

  @media (min-width: 1750px) {
    width: calc(300px + 0.5vmax);
    height: calc(300px + 0.5vmax);
  }
`

const PicturesCarousel = () => {
  const [pictures] = useState([
    { picture: Device2, id: "device-2" },
    { picture: Device3, id: "device-3" },
    { picture: Device4, id: "device-4" },
    { picture: Device5, id: "device-5" },
    { picture: Device6, id: "device-6" },
    { picture: Device1, id: "device-1" },
    { picture: Device7, id: "device-7" },
  ])

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    pauseOnHover: true,
  }

  return (
    <StyledWrapper>
      <Slider {...settings}>
        {pictures.map(({ picture, id }) => (
          <div key={id}>
            <StyledPicture src={picture} alt="device" />
          </div>
        ))}
      </Slider>
    </StyledWrapper>
  )
}

export default PicturesCarousel
