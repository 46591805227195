import styled, { css } from "styled-components"

const InformationText = styled.p`
    font-size: 15px;
    color: black;
    max-width: 500px;
    font-family: Roboto;
    margin: 8px 0px;
  @media (max-width: 600px) {
    width: 70vw;
    margin: 24px 0;
  }

  ${({ huge }) =>
    huge &&
    css`
      @media (max-width: 550px) {
        font-size: ${({ theme }) => theme.fontSize.xxs};
      }
    `}

  @media (min-width: 1750px) {
    font-size: ${({ theme }) => `calc(${theme.fontSize.xxxs} + 0.1vmax)`};
    max-width: 700px;
  }
`

export default InformationText
