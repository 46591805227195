import styled from "styled-components"

const InformationTitle = styled.h2`
  color: ${({ theme }) => theme.colors.home.informationTitle};
  font-size: ${({ theme, small }) =>
    small ? theme.fontSize.xxs : theme.fontSize.s};
  width: 100%;
  max-width: 70vw;
  position: relative;
  margin-bottom: 30px;
  text-transform: uppercase;
  z-index: 50;
  &:before {
    content: "";
    position: absolute;
    bottom: -15px;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.home.informationLine};
  }

  @media (max-width: 500px) {
    font-size: ${({ theme }) => theme.fontSize.xxxs};
  }
`

export default InformationTitle
