import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledButton = styled.button`
  width: 130px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightblue;
  text-transform: uppercase;
  border-radius: 15px;
  border: none;
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSize.xxxxs};
  margin: 0;
  z-index: 50;
  position: relative;
  cursor: pointer;
  color: black;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  transition: transform 0.25s ease;

  &:hover {
    transform: translateY(3px);
  }

  @media (min-width: 1750px) {
    font-size: ${({ theme }) => theme.fontSize.xxxs};
    width: 160px;
    height: 34px;
  }
`

const Button = ({ path, name, className }) => (
  <Link to={path} style={{ textDecoration: "none" }}>
    <StyledButton className={className}>{name}</StyledButton>
  </Link>
)

Button.propTypes = {
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.elementType,
}

export default Button
